import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Services from "./Services";
import "../../custom.css";
import TiekejaiNewModal from "./modals/TiekejaiNewModal";
import * as AiIcons from "react-icons/ai";
import { Button } from "reactstrap";

function createData(id,name, calories, fat, carbs, protein) {
  return {id, name, calories, fat, carbs, protein };
}

const data = [
  createData(1,"Cupcake", 305, 3.7, 67, 4.3),
  createData(56,"Donut", 452, 25.0, 51, 4.9),
  createData(74,"Eclair", 262, 16.0, 24, 6.0),
  createData(2,"Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData(3,"Gingerbread", 356, 16.0, 49, 3.9),
  createData(4,"Honeycomb", 408, 3.2, 87, 6.5),
  createData(7,"Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData(20,"Jelly Bean", 375, 0.0, 94, 0.0),
  createData(11,"KitKat", 518, 26.0, 65, 7.0),
  createData(10,"Lollipop", 392, 0.2, 98, 0.0),
  createData(9,"Marshmallow", 318, 0, 81, 2.0),
  createData(16,"Nougat", 360, 19.0, 9, 37.0),
  createData(17,"Oreo", 437, 18.0, 63, 4.0),
];

const headCells = [
  { id: "id", numeric: false, label: "Id" },
  { id: "name", numeric: false, label: "Vardas / Pavadinimas" },
  { id: "calories", numeric: false, label: "Kodas" },
  { id: "fat", numeric: true, label: "PVM kodas" },
  { id: "carbs", numeric: true, label: "Adresas" },
  { id: "protein", numeric: true, label: "Telefonas" },
  { id: "actions", numeric: true, label: "Veiksmai" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, name, index) => {
    console.log(index);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="main-layout">
      <hr></hr>
      <div className="button-wrap-outside">
        <div className="button-wrap-inner">
          <TiekejaiNewModal />
        </div>
        <div className="button-wrap-inner">
          <Button color="success" className="cus-button">
            <AiIcons.AiFillPrinter />
          </Button>
        </div>
      </div>
      <hr></hr>
      <div className={classes.root}>
          <TableContainer>
            <Table
              classes="custom-table"
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {Services.stableSort(data, Services.getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row,index)}
                        tabIndex={-1}
                        key={row}
                      >
                        <TableCell component="th" id={labelId} scope="row"> {row.id} </TableCell>
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">{row.calories}</TableCell>
                        <TableCell align="center">{row.fat}</TableCell>
                        <TableCell align="center">{row.carbs}</TableCell>
                        <TableCell align="center">{row.protein}</TableCell>
                        <TableCell align="center">
                          <Button color="success" className="cus-button">
                            <AiIcons.AiFillEdit />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
      </div>
    </div>
  );
}
