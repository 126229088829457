import React from 'react';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as GrIcons from "react-icons/gr";
import * as BsIcons from "react-icons/bs";

export const sidebarData = [
    {
        title: 'Tiekėjai',
        path: '/Tiekejai',
        icon: <IoIcons.IoMdPeople />
    },
    {
      title: 'Table',
      path: '/Table',
      icon: <IoIcons.IoMdTabletLandscape />
  },
    {
      title: 'Gavimai',
      path: '#',
      icon: <AiIcons.AiFillHome />,
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
  
      subNav: [
        {
          title: 'Naujas',
          path: '/Gavimai/Naujas',
          icon: <IoIcons.IoIosPaper />
        },
        {
          title: 'Sąrašas',
          path: '/Gavimai/Sarasas',
          icon: <IoIcons.IoIosPaper />
        }
      ]
    },
    {
      title: 'Parametrai',
      path: '#',
      icon: <BsIcons.BsGearFill />,
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
  
      subNav: [
        {
          title: 'Rūšys',
          path: '/Parametrai/Rusys',
          icon: <BsIcons.BsGearFill />
        },
        {
          title: 'Kokybė',
          path: '/Parametrai/Kokybe',
          icon: <BsIcons.BsGearFill />
        },
        {
          title: 'Dydžiai',
          path: '/Parametrai/Dydiai',
          icon: <BsIcons.BsGearFill />
        },
        {
          title: 'Spalvos',
          path: '/Parametrai/Spalvos',
          icon: <AiIcons.AiOutlineBgColors />
        },
        {
          title: 'Atspalviai',
          path: '/Parametrai/Atspalviai',
          icon: <AiIcons.AiOutlineBgColors />
        },
        {
          title: 'Pristatymo būdai',
          path: '/Parametrai/Pristatymas',
          icon: <FaIcons.FaShippingFast />
        }
      ]
    }
  ];