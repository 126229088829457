import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as GoIcons from "react-icons/go";
import { Form, FormGroup, Label, Input } from "reactstrap";
const TiekejaiNewModal = (props) => {
  const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Button color="success" className="cus-button" onClick={toggle}><GoIcons.GoDiffAdded/></Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalBody>
        <Form>
            <FormGroup>
              <Label for="name">Vardas/Pavadinimas</Label>
              <Input type="text" name="name" id="name" placeholder="" />
            </FormGroup>
            <FormGroup>
              <Label for="code">Kodas</Label>
              <Input type="text" name="code" id="code" placeholder="" />
            </FormGroup>
            <FormGroup>
              <Label for="pmvkodas">PVM kodas</Label>
              <Input type="text" name="pmvkodas" id="pmvkodas" placeholder="" />
            </FormGroup>
            <FormGroup>
              <Label for="adresas">Adresas</Label>
              <Input type="text" name="adresas" id="adresas" placeholder="" />
            </FormGroup>
            <FormGroup>
              <Label for="telefonas">Telefonas</Label>
              <Input
                type="text"
                name="telefonas"
                id="telefonas"
                placeholder=""
              />
            </FormGroup>
            <FormGroup>
              <Label for="epastas">El. Paštas</Label>
              <Input type="email" name="epastas" id="epastas" placeholder="" />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>Pridėti</Button>{' '}
          <Button color="secondary" onClick={toggle}>Atšaukti</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default TiekejaiNewModal;