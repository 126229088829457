import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home } from "./components/Home";
import Tiekejai from "./components/pages/Tiekejai";
import Rusiavimas from "./components/pages/Rusiavimas";
import CustomPaginationActionsTable from "./components/pages/TableComp";
import Dydziai from "./components/pages/Dydziai";
import Sidebar from "./components/sidebar/sidebar";

import "./custom.css";

  const App = () => {
    const [sidebar, setSidebar] = useState(false);

    
    //functions
    const showSidebar = () => {
      setSidebar(!sidebar);
      console.log(sidebar);
    }

    return (
      <div>
        <Router>
        <Sidebar sidebar={sidebar} showSidebar={showSidebar} />
          <Switch>
            
            <Route exact path="/" component={Home} />
            <Route  path="/Tiekejai" exact component={Tiekejai} showSidebar={showSidebar} />
            <Route  path="/Rusiavimas" exact component={Rusiavimas} />
            <Route  path="/Table" exact component={CustomPaginationActionsTable} />
            <Route  path="/Parametrai/Dydiai" exact component={Dydziai} />

          </Switch>
        </Router>
      </div>
    );
  }
export default App;
