import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import "./sidebar.css";

import { sidebarData } from "./sidebarData";
import SubMenu from "./SubMenu";


function Sidebar({ sidebar, showSidebar }) {
  return (
    <>
      <div>
        <div className="navbar">
          <Link to="#" className="menu-bars menu-bars-x">
            <FaIcons.FaBars onClick={showSidebar} />
          </Link>
        </div>

        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items" >
            <div className="menu-bars-x">
            <Link to="#" className="menu-bars">
              <AiIcons.AiOutlineClose onClick={showSidebar}/>
            </Link>
            </div>
            {sidebarData.map((item, index) => {
              return <SubMenu item={item} key={index} showSidebar={showSidebar} />;
            })}
          </ul>
        </nav>
      </div>
    </>
  );
}

export default Sidebar;
