import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as GoIcons from "react-icons/go";
import * as AiIcons from "react-icons/ai";
import { Form, FormGroup, Label, Input } from "reactstrap";
import axios from "axios";


const EditModal = (props) => {
  const {
    className,
    index,
    data,
    item
  } = props;

  const [modal, setModal] = useState(false);
  const [kodas, setKodas] = useState(item.kodas);
  const [pavadinimas, setPavadinimas] = useState(item.pavadinimas);

  const toggle = () => setModal(!modal);

  function handleSubmit () {
    console.log(kodas);
    console.log(pavadinimas);
    if(kodas.length > 0 )
    {
      data[index].kodas=kodas;
    }
    if(pavadinimas.length > 0 )
    {
      data[index].pavadinimas=pavadinimas;
    }

    axios.put('/api/Dydziai/', {
      id:        Number(item.id),
      kodas:     kodas,
      pavadinimas:     item.pavadinimas
    }).then(
        props.setData([...data]),
        setTimeout(toggle(),0)
    ).catch();
  }

    
  return (
    <div>
      <Button color="warning" className="cus-button" onClick={toggle}><AiIcons.AiFillEdit /></Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalBody>
        <Form>
            <FormGroup>
              <Label for="name">Kodas</Label>
              <Input type="text" name="name" value={kodas}  onChange={e=>setKodas(e.target.value)}placeholder={item.kodas} />
            </FormGroup>
            <FormGroup>
              <Label for="code">Pavadinimas</Label>
              <Input type="text" name="code" value={pavadinimas} onChange={e=>setPavadinimas(e.target.value)} placeholder={item.pavadinimas} />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>Pridėti</Button>{' '}
          <Button color="secondary" onClick={toggle}>Atšaukti</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default EditModal;