import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import "../../custom.css";
import { Button } from "reactstrap";
import { Table } from "reactstrap";
import TiekejaiNewModal from "./modals/TiekejaiNewModal";

function Tiekejai({ showSidebar }) {
  

  return (
    <div>
      <div className="main-layout">
        <h1 className="H-text">Tiekėjai</h1>

        <div className="button-wrap-outside">
          <div className="button-wrap-inner">
            <TiekejaiNewModal />
          </div>
          <div className="button-wrap-inner">
            <Button color="success" className="cus-button">
              <AiIcons.AiFillPrinter />
            </Button>
          </div>
        </div>
        <div className="table-wrap">
          <Table className="table-overflow">
            <thead>
              <tr>
                <th>#</th>
                <th>
                  Vardas / <br></br>Pavadinimas
                </th>
                <th>Kodas</th>
                <th>PVM kodas</th>
                <th>Adresas</th>
                <th>Telefonas</th>
                <th>Veiksmai</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
                <td>stuff</td>
                <td>stuff2</td>
                <td>
                  <Button color="success" className="cus-button">
                    <AiIcons.AiFillEdit />
                  </Button>
                </td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Larry</td>
                <td>the Bird</td>
                <td>@twitter</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default Tiekejai;
