import React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Services from "./Services";
import "../../custom.css";
import TiekejaiNewModal from "./modals/TiekejaiNewModal";
import EditModal from "./modals/Dydziai/EditModal";
import * as AiIcons from "react-icons/ai";
import { Button } from "reactstrap";
import axios from "axios";


const headCells = [
  { id: "id", type:"text", numeric: false, label: "Id" },
  { id: "kodas", type:"text", numeric: false, label: "Kodas" },
  { id: "pavadinimas", type:"text", numeric: false, label: "Pavadinimas" },
  { id: "nerodyti", type:"text", numeric: true, label: "nerodyti" }
];

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function Dydziai() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, name, index) => {
    console.log(index);
    console.log(name);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    axios.get('/api/Dydziai')
      .then(res => {
        setData(res.data);
      });
   }, []);

  return (
    <div className="main-layout">
      <h2>Dydžiai</h2>
      <hr></hr>
      <div className="button-wrap-outside">
        <div className="button-wrap-inner">
          <TiekejaiNewModal />
        </div>
        <div className="button-wrap-inner">
          <Button color="warning" className="cus-button">
            <AiIcons.AiFillPrinter />
          </Button>
        </div>
      </div>
      <hr></hr>
      <div className={classes.root}>
          <TableContainer>
            <Table
              className="custom-table"
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {Services.stableSort(data, Services.getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row,index)}
                        tabIndex={-1}
                      >
                        <TableCell component="th" id={labelId} scope="row"> {row.id} </TableCell>
                        <TableCell align="center">{row.kodas}</TableCell>
                        <TableCell align="center">{row.pavadinimas}</TableCell>
                        <TableCell align="center">{row.nerodyti}</TableCell>
                        <TableCell align="center">
                          <EditModal setData={setData} data={data} item={row} index={index}/>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
      </div>
    </div>
  );
}
