import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import "./sidebar.css";



const SubMenu = ({ item, showSidebar }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <Link className="SidebarLink" onClick={showSidebar} to={item.path} onClick={item.subNav && showSubnav}>
        <div>
          {item.icon}
          {item.subNav && <label className="SidebarLabel" >{item.title}</label>}
          {!item.subNav && <label className="SidebarLabel" onClick={showSidebar} >{item.title}</label>}
          
        </div>
        <div>
          {item.subNav && subnav ? item.iconOpened: item.subNav
            ? item.iconClosed: null}
        </div>
      </Link>
      {subnav &&
        item.subNav.map((item, index) => {
          return (
            <Link className="DropdownLink" to={item.path} key={index}>
              {item.icon}
              <label className="SidebarLabel" onClick={showSidebar}>{item.title}</label>
            </Link>
          );
        })}
    </>
  );
};

export default SubMenu;